.root {
  max-width: 550px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
  width: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 0 0 20px;

  .buttonGroup {
    text-align: center;
    padding: 20px 0 0;
  }
}
.notificationsList {
  padding: 20px 0 0;
  height: 300px;
  overflow: auto;
  position: relative;
}

.modalTitle {
  padding: 22px 47px 0 !important;
}

.notificationsListItem {
  padding: 16px 70px 6px 68px;
  font-size: 20px;
  cursor: pointer;
  transition: background .5s;
  border-top: 1px solid #BFBFBF;
  position: relative;

  &:hover{
    background: #E3E3E3;
  }
}

.activeIssuesDot {
  width: 8px;
  height: 8px;
  background: #FF0000;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  right: 44px;
  top: 19px;
}