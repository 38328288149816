@import 'config/theme.scss';

.main {
  margin: 32px 26px 0;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background-color: white;
  padding: 30px 15px 30px;
}

.root {
  padding: 0 0 30px;
}

.introBar {
  background: map-deep-get($theme, 'palette', 'secondary2', 'main');
  padding: 20px 0 40px;
}
.introBarTitle {
  color: white;
  font-size: 26px;
  line-height: 38px;
}

.tabItem {
  margin: 12px 14.3px 0;
  font-weight: 700;
  letter-spacing: -0.1;
  cursor: pointer;
  transition: .5s;
  opacity: 0.5;
}

.tabItem__active {
  opacity: 1;
}

.tabControls {
  display: flex;
  color: white;
  margin: 0 -14.3px;
  list-style: none;
  padding: 10px 0 0;
}