.searchWrap {
}

.input {
  width: 375px;
  color: #C9C9C9;
}

.label {
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  width: 80px;
}