.root {
  //padding: 10px 0 0;
}

.dataItem {
  border: 1px solid #C9C9C9;
  padding: 7px 78px 12px 14px;
  margin: 10px 0 0;
  max-height: 490px;
  overflow: auto;
}

.dataItemTitle {
  font-weight: 700;
}