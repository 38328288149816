.mainSingleContainerWrapper {
  position: relative;
  top: -44px;
  margin: 0 0 -44px;
}
.groupTitle {
  color: white;
  font-size: 32px;
  line-height: 38px;
}

.progressBarWrap {
  position: relative;
}

.downloadsContainerWidget {
  max-width: 600px;
  margin: 47px 0 0;
}