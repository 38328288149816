.field {
  border: none;
  border-bottom: 1px solid #6D6D6D;
  width: 100%;
  height: 24px;
  font-size: 16px;
  margin: 0;
  padding: 6px 0 7px;
  background: transparent;
}

.error {
  border-bottom: 2px solid #f44336;
  &::placeholder {
    color: #f44336;
  }
}