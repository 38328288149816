@import "config/theme.scss";

$primaryMain: map-deep-get($theme, 'palette', 'primary', 'main');
$primaryError: map-deep-get($theme, 'palette', 'error', 'main');
$secondaryGrey: #333333;


.buttonGroup {
  margin: 0 -8px;
  .button {
    margin: 0 8px;
  }
}

.button {
  &.formButton {
    font-size: 15px;
    padding: 7.5px 40.5px;
    border-radius: 5px;
    transition: background-color .5s, opacity .5s;
  }

  &.formButton__mainOutlined{
    border-color: $primaryMain;
    color: $primaryMain;
    padding: 5px 38px;
    border: 2.5px solid;
    &:hover {
      background: rgba($primaryMain, .14);
    }
  }

  &.formButton__mainContained{
    background: $primaryMain;
    color: white;
    &:hover {
      opacity: .7;
    }
  }

  &.formButton__secondaryOutlined {
    border-color: $secondaryGrey;
    color: $secondaryGrey;
    padding: 5px 38px;
    border: 2.5px solid;
    &:hover {
      background: rgba($secondaryGrey, .14);
    }
  }

  &.formButton__secondaryContained {
    background: $secondaryGrey;
    color: white;
    &:hover {
      opacity: .7;
    }
  }

  &.formButton__errorContained{
    background: $primaryError;
    color: white;
    &:hover {
      opacity: .7;
    }
  }
}