.title {
    font-size: 22px;
    line-height: 32px;
}

.header {
    display: flex;
    padding: 18px 13px 12px 34px;
    justify-content: space-between;
    border-bottom: 1px solid #C9C9C9;
}

.root {
    border-radius: 10px;
    box-shadow: 1px 1px 7px #a7a4a4;
    background: white;
    position: relative;
}

.body {
    padding: 20px 41px 42px;
    margin: 0 -10px;
}

.tableWrap {
    padding: 10px 0 0;
}

.downloadSectionListItem {
    padding: 15px 0 0;
}