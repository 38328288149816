.root {
  position: relative;

  .textField {
    width: 100%;
    box-sizing: border-box;
    margin: 20px 0 0;
  }

  .controls {
    padding: 20px 0 0;
    text-align: center;
  }
}

.form {
  padding: 30px 0 0;
  position: relative;
}

.inputWrap {
  width: 100%;

  >div {
    width: 100%;
  }
}