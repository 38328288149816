.root {
  padding: 3px 0 0;

  .listItem {
    font-size: 15px;
    display: flex;
    align-items: center;
    margin: 0 -10px 0;
    padding: 0 10px 0;
    justify-content: space-between;
    transition: background .5s;
    cursor: pointer;

    &:hover {
      background: rgba(0, 0, 0, 0.07);
    }
  }

  .icon {
    width: 16px;
    height: 16px;
    color: #333333;
  }

  .deleteBtn {
    padding: 6px !important;
  }

  .iconClose {
    width: 20px;
    height: 20px;
    color: #333333;
  }
}