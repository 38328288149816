@import "config/theme.scss";

.footer {
  background: map-deep-get($theme, "palette", "secondary2", "main");
  color: white;
  padding: 9px 29px;
  letter-spacing: -0.7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 52px;
  box-sizing: border-box;

  p {
    margin: 0;
  }

  a {
    color: white;
    text-decoration: none;
    transition: opacity 0.5s;
    &:hover {
      opacity: 0.7;
    }
  }

  .footerRightCol {
    text-align: right;
  }
}
