.root {
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  margin: 0 0 1rem;
  overflow: auto;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 12px 26px 0;
}

.statusName {
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -1.3px;
  color: #333333;
}

.statusCount {
  font-size: 17px;
  line-height: 33px;
  font-weight: bold;
  color: #000000;
}

.active {
  border-bottom: 5px solid #53C4CC;
  cursor: default;

  .statusName, .statusCount {
    color: #53C4CC;
    font-weight: 700;
  }
}

