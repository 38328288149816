@import 'config/theme.scss';

.root {
  background: map-deep-get($theme, 'palette', 'primary', 'main');
  color: white;
  display: inline-block;
  border-radius: 5px;
  padding: 7px 21px 7px 26px;
  font-size: 15px;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  transition: .5s;

  &:hover {
    opacity: .7;
  }

  > svg {
    width: 10px;
    height: 10px;
    position: absolute;
    left: 9px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.showProgressBar {
  pointer-events: none;
}