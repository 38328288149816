.root {
  margin: 0 -5px;
}

.btn {
  background: #F2F0F3;
  padding: 3px 3px;
  border-radius: 3px;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
  cursor: pointer;
  margin: 0 5px;
  transition: box-shadow .5s, background-color .5s;

  &:hover {
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
    background-color: #d5d5d5;
  }
}