.sidebarMenuWrap {
  height: calc(100% - 59px);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.sidebarLogoWrap {
  background: #42484C;
  height: 58px;
  display: flex;
  align-items: center;
  overflow: hidden;
  @media (max-width: 599px) {
    display: none;
  }
}

.sidebarLogo {
  color: white;
  white-space: nowrap;
  font-size: 19px;
  letter-spacing: 1.2px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 0 2px;

  span {
    padding: 0 0 0 10px;
  }
}

.logo {
  width: 52px;
  height: 50px;
  min-width: 52px;
}

.closeButton {
  position: absolute;
  left: calc(100% - 12px);
  top: 65px;
  background: #F2F0F3;
  border: none;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  color: black;
  border-radius: 50%;
  box-shadow: 0 0 6px black;
  z-index: 100;
  @media (max-width: 599px) {
    display: none;
  }

  &.sidebarOpen {
    svg {
      transform: rotateY(-180deg) translateX(4px);
    }
  }

  svg {
    font-size: 14px;
    transition: transform .5s;
    transform-origin: center center;
    transform: rotateY(0deg) translateX(-3px);
  }
}