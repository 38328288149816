.suggestionsPatients {
  padding: 10px 0 0;
}

.suggestionsPatientsList {
  position: relative;
  list-style: none;
  overflow: auto;
  margin: 0;
  height: 150px;
  padding: 0;

  >li {
    transition: background .5s;
    cursor: pointer;
    padding: 5px 10px;
    &:hover {
      background: rgba(#000000, .1);
    }
  }
}

.suggestionsPatientsHeader {
  background: rgba(83, 196, 204, 0.2);
  padding: 10px 10px;
}