@import "config/theme.scss";

.base-table {

  th, td {
    font-size: 12px;
  }

  th {
    padding: 16px 2px 16px 2px;
    &:first-child {
      padding: 16px 0 16px 7px;
    }
  }
  td {
    line-height: 1.3;
    padding: 4px 2px;
    &:first-child {
      padding: 0;
    }
  }

  th svg {
    width: 12px;
    margin: 0;
  }

  .tableHeaderAddButtonGroup {
    margin: -5px -5px;
    display: inline-block;

    > * {
      margin: 5px 5px 0;
    }
  }

  .checkbox-col {
    width: 30px;
     >span {
       padding: 4px;
     }
  }

  .actions-col {
    padding: 0 5px;
    text-align: right;
  }

  .action-col_button-group {
    display: flex;
  }

  .EmptyRow {
    text-align: center;
  }

  .sub-table {
    .table-body {
      background: rgba(187, 187, 187, 0.2);
    }
  }
}