@import "../config/theme";
@import "typography";
@import "buttons/buttons";
@import "single-page";
@import "modal";

* {
  outline: none;
}

.mainContainer {
  flex-grow: 2;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow: auto;

  .mainContainer__inner {
    padding: 1rem 0.5rem 1rem;
  }
}

.mainSingleContainer {
  padding: 0 map-deep-get($theme, 'mainLayout', 'paddingRight') 0 map-deep-get($theme, 'mainLayout', 'paddingLeft');
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

.rectOuter {
  position: relative;
}
.rectInner {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.react-datepicker__current-month {
  font-size: .8rem;
}

.react-datepicker__day-name {
  width: 1rem
}

.react-datepicker__day {
  width: 1rem;
}

.text-link {
  color: blue;
  transition: opacity .5s;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    opacity: .7;
  }
}