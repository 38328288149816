.rootTheme_contact {
  .icon {
    color: #a1a1a1;
  }
  .itemLabel {
    color: #a1a1a1;
  }
  .itemValue {
    color: #a1a1a1;
  }
}

.itemLink {
  color: blue;
  cursor: pointer;
  transition: .5s;
  text-decoration: none;

  &:hover{
    opacity: .7;
  }
}

.itemValue {
  color: rgba(0, 0, 0, 0.54);
}

.listTitle {
  margin: 20px 0 0;
}

.itemsList {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
}

.item {
  width: 33.3333%;
  position: relative;
  box-sizing: border-box;
  margin: 20px 0 0;
  padding: 0 10px 0;
  min-height: 40px;

  &.itemWithIcon {
    padding: 0 10px 0 48px;
  }
}

.icon {
  color: #2B479D;
  width: 28px;
  height: 28px;
  position: absolute;
  left: 8px;
  top: 2px;

  svg {
    width: 100%;
    height: 100%;
  }
}