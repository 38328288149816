.actionColButtonGroup {
  display: flex;
}

.tableRow {
  height: auto !important;
}

.tableCell {
  padding: 0 !important;
  border: none !important;
}

.collapse {
  padding: 0 0 0 50px;
}