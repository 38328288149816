.root {
  max-width: 640px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
  width: 100%;
  border-radius: 10px;
  padding: 37px 45px;
  box-sizing: border-box;

  .buttonGroup {
    text-align: center;
    padding: 40px 0 0;
  }


  .inputsGroup {
    margin: 20px -10px 0;
    display: flex;
    flex-wrap: wrap;
  }

  .textField {
    width: 100%;
    margin: 0;
  }

  .textFieldWrap {
    width: 50%;
    padding: 0 10px;
    box-sizing: border-box;
  }
}
