@import 'config/theme.scss';

.root {
  display: flex;
  justify-content: space-between;
}
.inputList {
  margin: 0 -10px;
  display: flex;
  width: 100%;
}

.inputWrap {
  max-width: 200px;
  margin: 7px 10px 0;
  width: 25%;

  >div {
    margin-top: 16px;
    margin-bottom: 0;
    display: flex;
    align-items: flex-end;
    width: 100%;
  }
  >div>div{
    width: 100%;
  }
}

.button {
  margin: 0 8px !important;
  font-size: 15px !important;
  font-weight: 700 !important;
  padding: 4px 8px 6px 11px !important;
  border-radius: 5px !important;
  border: 2px solid !important;
  transition: opacity .5s !important;
  &:hover {
    opacity: .7;
  }

  svg {
    width: 20px;
    height: 20px;
    margin: 0 5px 0 0;
  }
}

.buttonOutlined {
  border-color: map-deep-get($theme, 'palette', 'primary', 'main') !important;
  color: #3F51B5 !important;
}

.formControls {
  display: flex;
  align-items: flex-end;
}