.root {
  margin: auto;
  max-width: 1130px;
  padding: 20px 0 0;

  .title {
    font-size: 22px;
    letter-spacing: -0.3px;
  }

  .selectedClients {
    padding: 30px 0 0;
  }

  .tableWrap {
    padding: 26px 0 0;
  }
}