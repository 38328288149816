@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }
  @return $map;
}

@mixin map-to-props($map, $parentKey) {
  @if type-of($map) == map {
    @each $key, $value in $map {
      $returnKey: #{$key};
      @if $parentKey != null {
        $returnKey: (#{$parentKey}-#{$key});
      }
      @if type-of($value) != map {
        #{$returnKey}: #{inspect($value)};
      } @else {
        @include map-to-props($value, $returnKey);
      }
    }
  }
}

$theme: (
  palette: (
      primary: (
              main: #0052cc
      ),
      secondary: (
              main: #53C4CC
      ),
      secondary2: (
              main: #8A87C4
      ),
      error: (
              main: #f44336
      )
  ),
  mainLayout: (
      paddingLeft: 0.5rem,
      paddingRight: 0.5rem,
  ),
  smartViewSidebar: (
    width: 189px
  )
);

:export {
  @include map-to-props($theme, null)
}