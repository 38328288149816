@import 'config/theme.scss';

.root {
  max-width: 940px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
  width: 100%;
  border-radius: 10px;
  padding: 37px 45px;
  box-sizing: border-box;

}

.modalControls {
  padding: 0 10px;
}
.modalHeaderTitle {
  padding: 0 10px !important;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  margin: 0 -10px;
}

.inputWrap {
  width: 300px;
  padding: 20px 0 0;

  >div {
    width: 100%;
    margin-top: 0;
  }
}

.tableWrap {
  padding: 20px 0 0;
}

.confirmMessage {
  .title {
    padding: 10px 0 0;
  }

  >li {
    margin: 15px 0 0;
  }
}

.formErrorMessage {
  padding: 10px 0 0;
  color: map-deep-get($theme, 'palette', 'error', 'main');
}