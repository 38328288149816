body {
  font-size: 14px;
}

.ttl {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

.ttl-1 {

}

.ttl-2 {
  font-size: 27px;
  font-weight: 700;
}

.ttl-3 {
  font-size: 27px;
}

.ttl-6 {
  font-size: 14px;
}

.text-center {
  text-align: center;
}