.radioGroupError {
 .radioInputLabel {
   color: red;
 }
}

.radioInputLabel {
   letter-spacing: 0.3px;
   font-size: 14px;
 }

.radioGroup {
  padding: 0 13px;
  flex-direction: row !important;
}