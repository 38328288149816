@import 'config/theme.scss';

.root {
  border: 1px solid #C9C9C9;
  position: relative;
  padding: 7px 405px 12px 14px;
}

.media {
  height: 0;
  padding-top: 56.25%;
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  font-size: 13px;
  > li {
    width: 33.333%;
    padding: 15px 0 0;
  }

  strong {
    font-weight: 700;
  }
}


.description {
  font-weight: 700;
  letter-spacing: -0.6px;
  padding: 9px 0 0;
}

.note {
  letter-spacing: -0.6px;
  padding: 9px 0 0;
}

//.noteCollapsed {
//  overflow: hidden;
//  text-overflow: ellipsis;
//  white-space: nowrap;
//}

.listPreview {
  padding: 11px 0 0;
}

.itemHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
}

.closeButtonWrap {
  width: 105px;
  display: inline-block;
  margin: 0 20px;
}
.controls {
  margin: 0 -20px;
  position: absolute;
  right: 24px;
  top: 50px;
  transform: translateY(-50%);
}

.selectWrap {
  display: inline-flex;

  >div {
    margin: 0 20px !important;
    width: 165px;
  }
}

.loadingProcess {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(#ffffff, .7);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}
