.root {
  display: flex;
  justify-content: space-between;

  >div {
    max-width: 110px;
  }
}

.field {
  border: 1px solid #6D6D6D;
  width: 100%;
  height: 28px;
  font-size: 13px;
  margin: 0;
  box-sizing: border-box;
  padding: 6px 5px;
}