.rootWrap {
  display: flex !important;
  align-items: flex-end;
  >div:first-child {
    width: 61px;
  }

  > div > div > div {
    margin: 0;
  }

}