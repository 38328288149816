.root {
  text-align: center;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin: 5px 0 0;
  }
}