.radioGroupError {
 .radioInputLabel {
   color: red;
 }
}

.radioInputLabel {
  font-weight: 700;
  letter-spacing: 0.3px;
  font-size: 15px;
}

.formGroupWrap {
  position: relative;
}