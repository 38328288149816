.widgetList {
  display: flex;
  flex-wrap: wrap;
}

.widgetListItem {
  position: relative;
  box-sizing: border-box;
  margin: 20px 0 0;
  padding: 0 0 0 50px;
  width: 50%;
  min-height: 38px;

  svg {
    position: absolute;
    left: 0;
    top: 4px;
    color: #2B479D;
    width: 33px;
    height: 33px;
  }
}