.root {
  max-width: 1240px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
  width: 100%;
  border-radius: 10px;
  padding: 37px 45px;
  box-sizing: border-box;

  .buttonGroup {
    text-align: center;
    padding: 40px 0 0;
  }
}

.modalHeader {
  display: flex;
  justify-content: space-between;
}
