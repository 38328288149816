.root {
  position: relative;
}

.rootDisabled {
  &:before {
    content: '';
    background: rgba(white, .7);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }
}