.root {

}

.inputWrap {
  max-width: 300px;
  padding: 20px 0 0;
  >div {
    width: 100%;
  }
}

.formControls {
  padding: 30px 0 0;
}