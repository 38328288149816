.root {
  width: 100%;
  margin: 10px 0 0;
}

.listWrap {
  margin: 10px 0 0;
}

.workLogList {
  margin: 0;
  padding: 0;
  list-style: none;

  >li {
    padding: 3px;
    border-radius: 5px;
    +li {
      margin: 2px 0 0;
    }

    &:nth-child(odd){
      background: #ececec;
    }
    &:nth-child(even){
      background: #f7f7f7;
    }
  }
}