.inputGroup {
    width: 100%;
    max-width: calc(320px - 20px);
    flex-direction: column;
    margin: 20px 10px 0;
}

.inputWrap {
    max-width: 220px;
    >div {
        width: 100%;
    }
}

.selectedOptionsList {
    padding: 10px 0 0;
    font-size: 13px;


    >div {
        padding: 6px 0 0;
    }
}
