.root {
  display: flex;
  margin: 0 -10px;

  > div {
    padding: 0 10px;
    width: 50%;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin: 5px 0 0;
  }
}

.colClientInfo {
  text-align: center;
}