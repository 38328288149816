.root {
  padding: 23px 0 0;
}

.formTitle {
  font-weight: 700;
  font-size: 15px;
  padding: 15px 23px;
}

.main {
  width: 100%;
  display: flex;
  margin: 0 -10px;
}

.tableWrap {
  padding: 20px 0 0;
}

.infoCol {
  width: 58%;
  padding: 0 10px 0 10px;

  >div + div {
    //margin: 20px 0 0 !important;
  }
}

.infoColFullWidth {
  width: calc(100% - 40px);
  margin: 0 20px;
}

.uploadCol {
  width: 42%;
  padding: 0 10px 0 10px;
  //margin: 0 20px;
}

