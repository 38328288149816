:global {
  .apexcharts-tooltip-active {
    opacity: 1 !important;
    transition: 0.15s ease all !important;
  }
  .apexcharts-tooltip {
    z-index: 10 !important;
    pointer-events: unset !important;
  }
}
