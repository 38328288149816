.collapseTrigger {
  cursor: pointer;
  transition: opacity .5s;
  span {
    display: block;
    text-align: center;
  }
  &:hover {
    opacity: .7;
  }
}