.root {
  border-radius: 10px;
  box-shadow: 1px 1px 7px #a7a4a4;
  background: white;
  position: relative;
  width: 100%;
}

.widgetHeader {
  padding: 3px 15px 3px 15px;
  font-size: 17px;
  line-height: 24px;
  border-bottom: 1px solid #C9C9C9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 38px;
  box-sizing: border-box;
}

.widgetContent {
  padding: 0 15px 15px;
}