@import "config/theme.scss";

.root {
  color: white;
  display: inline-block;
  border-radius: 5px;
  font-size: 1rem;
  position: relative;
  text-decoration: none;
  transition: opacity 0.5s;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  @media (min-width: 600px) {
    padding: 0.7rem 2.9rem 0.7rem 2.2rem;
    background: map-deep-get($theme, "palette", "primary", "main");
  }
}

.plusIcon {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0.6rem;
  top: 50%;
  transform: translateY(-50%);
}

.lightingIcon {
  width: 20px;
  height: 20px;
}

.lightingIconWrap {
  width: 2.4rem;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
