.patientContent {
  display: flex;
  margin: 0 -10px;
}

.patientListInputsCol {
  width: 68%;
  padding: 0 10px;
}

.suggestedPatientListCol {
  width: 32%;
  padding: 0 10px;
}