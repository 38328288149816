.root {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100vh;
}

.introSection {
  text-align: center;
  @media (min-width: 600px) {
    padding: 200px 0 0;
  }
}

.logo {
  max-width: 350px;
  display: inline-block;
  width: 100%;
  height: auto;
}

.title {
  font-size: 24px;
  font-weight: normal;
}

.userName {
  font-size: 24px;
}