.root {
  padding: 23px 0 0;
  display: flex;
}

.formTitle {
  font-weight: 700;
  font-size: 15px;
  padding: 15px 23px;
}

.sidebar {
  border: 1px solid #C9C9C9;
  width: 21%;
}

.main {
  width: 79%;
  padding: 0 10px;
}

.mainFullWidth {
  width: 100%;
  padding: 0 10px;
}

.form {
  padding: 11px 22px;
}

.inputWrap {
  display: inline-flex;
  margin-top: 16px;
  margin-bottom: 8px;
  width: 100%;
  vertical-align: bottom;

  >div {
    width: 100%;
  }
}

.formControls {
  padding: 29px 0 0;
}

.submitButton {
  font-weight: 700 !important;
}

.radioInputLabel {
  composes: radioInputLabel from '../../../../../ui_components/smart_input/RadioContainer.module.scss';
  font-weight: 700;
}

.radioGroup {
  composes: radioInputLabel from '../../../../../ui_components/smart_input/RadioContainer.module.scss';
  flex-direction: column !important;
}

.formGroup {
  padding: 10px 0 0;
}

.formGroup-group2 {
  .groupInputsList {
    .inputWrap {
      width: 33.333%;
    }
  }
}
.formGroup-group3 {
  .groupInputsList {
    .inputWrap {
      width: 33.333%;
    }
  }
}
