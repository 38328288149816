.root {
  padding: 23px 0 0;
}

.formTitle {
  font-weight: 700;
  font-size: 15px;
  padding: 15px 23px;
}

.main {
  width: 100%;
  padding: 0 10px;
}

.form {
  padding: 11px 22px;
}

.inputWrap {
  >div {
    width: 100%;
  }
  +.inputWrap {
    padding: 23px 0 0;
  }
}

.formControls {
  padding: 29px 0 0;
}

.submitButton {
  font-weight: 700 !important;
}

