.root {
  padding: 23px 0 0;
}

.formTitle {
  font-weight: 700;
  font-size: 15px;
  padding: 15px 23px;
}

.main {
  width: 100%;
  padding: 17px 40px 17px 29px;
  box-sizing: border-box;
}

.tableWrap {
  padding: 20px 0 0;
}