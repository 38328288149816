.root {
  .main {
    margin: 32px 26px 0;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    background-color: white;
    padding: 30px 15px 30px;
  }

  .stepper {
    background: transparent;
    padding: 24px 24px 0;
  }

  .controls {
    text-align: center;
    padding: 39px 0 0;


    .button {
      margin: 0 10px;

    }
  }

  .form {
    margin: auto;
    max-width: 1130px;
  }

  .radio-group {
    flex-direction: column;
  }
}

.inputWrap {
  width: 33.3333%;
  box-sizing: border-box;
  padding: 0 30px 0 0;
  margin: 89px 0 0 0;
  display: inline-flex;
  vertical-align: top;

  >div {
    width: 100%;
  }
}

.inputWrap-salesNote {
  width: 100%;
}
