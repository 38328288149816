@import "config/theme.scss";

.root {
  max-width: 1240px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
  width: 100%;
  border-radius: 10px;
  padding: 37px 45px;
  box-sizing: border-box;

  .buttonGroup {
    text-align: center;
    padding: 40px 0 0;
  }
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
}

.progressBar {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(#ffffff, .7);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.errorMessage {
  padding: 15px 0 0;
  color: map-deep-get($theme, 'palette', 'error', 'main');
}