@import 'config/theme.scss';

.root {
  max-width: 600px;
}

.inputList {
  margin: 0 -10px;
}

.inputWrap {
  display: inline-flex;
  margin: 20px 10px 0;

  >div {
    width: calc(100% - 20px);
  }
}

.inputWrap-issueCode {
  width: 70%;
}

.inputWrap-reporterDeptId {
  width: calc(50% - 20px);
}

.inputWrap-resolverDeptId {
  width: calc(50% - 20px);
}

.inputWrap-issueDesc, .inputWrap-issueNote {
  width: 100%;
}