@import "config/theme.scss";

.tagsWrap {
  margin: -9px 0 0;
  display: flex;
  align-items: center;
}

.clearAllButton {
  display: inline-block;
  color: map-deep-get($theme, 'palette', 'primary', 'main');
  font-size: 14px;
  transition: .5s;
  cursor: pointer;
  margin: 9px 0 0;

  &:hover {
    opacity: .7;
  }
}