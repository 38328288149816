.root {
  padding: 20px 0 0;
}

.container {
  max-width: 1470px;
  padding: 0 57px 0 57px;
}

.salesRepCode {
  padding: 0 0 20px;
}