@import 'config/theme.scss';

.root {
  padding: 0 0 20px;
}
.container {
  padding: 12px 15px 0;
}

.groupTitle {
  color: white;
  font-size: 32px;
  line-height: 38px;
}


.controls {
  text-align: center;
  padding: 39px 0 0;

  button {
    margin: 0 10px;
  }
}

//.button {
//  margin: 0 8px !important;
//  font-size: 15px !important;
//  font-weight: 700 !important;
//  padding: 4px 8px 6px 11px !important;
//  border-radius: 5px !important;
//  border: 2px solid !important;
//  transition: opacity .5s !important;
//  &:hover {
//    opacity: .7;
//  }
//
//  svg {
//    width: 13px;
//    height: 13px;
//    margin: 0 5px 0 0;
//  }
//}
//
//.buttonOutlined {
//  border-color: map-deep-get($theme, 'palette', 'primary', 'main') !important;
//  color: #3F51B5 !important;
//}
//
//.buttonContained{
//  background: map-deep-get($theme, 'palette', 'primary', 'main') !important;
//  border-color: map-deep-get($theme, 'palette', 'primary', 'main') !important;
//  color: white !important;
//}