@import "config/theme.scss";

.actionColButtonGroup {
  display: flex;
}

.tableCell {
  padding: 0 !important;
  border: none !important;
}

.tableCell-tat {
  min-width: 150px;
}

.tableCellCollapseTrigger {
  width: 50px;
  padding: 4px 10px 4px 10px !important;
}

.cellValueLink {
  color: blue;
  cursor: pointer;
  text-decoration: none;
}

.actionIcon {
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25);
  color: map-deep-get($theme, 'palette', 'primary', 'main');
  width: 29px;
  height: 29px;
  margin-left: 6px;
  padding: 5px;
}

