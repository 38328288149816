@import 'config/theme.scss';

.root {
  background: map-deep-get($theme, 'palette', 'primary', 'main');
  min-height: 100vh;

  .container {
    max-width: 400px;
    margin: auto;
    padding: 40px 0 0;
  }

  .form {
    max-width: 300px;
    margin: 0 auto 0;
    padding: 20px;
    border-radius: 4px;
    background: white;
  }

  .controls {
    padding: 20px 0 0;
    text-align: center;
  }

  .textField {
    padding: 10px 0 0;
  }
}

.formWrapper {
  padding: 30px;
  background: white;
  display: flex;
  flex-direction: column;

  >div {
    margin: 20px 0 0;
  }
}

.buttonGroup {
  text-align: center;
}
