.root {
  padding: 20px 0 0;

  >div {
    +div {
      margin: 10px 0 0;
    }

    &:first-child {
      border-radius: 8px 8px 0 0;
    }

    &:last-child {
      border-radius: 0 0 8px 8px;
    }
  }
}

.progressBarWrap {
  position: relative;
}