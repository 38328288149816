.root {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 -10px 8px;

  >* {
   margin: 0 10px; 
  }
}

.tableToolBarActions {
  display: flex;
}

.toolbarCheckAllRowsBtn, .bulkActionsBtn {
  width: 40px;
  height: 33px;
  background: #FFFFFF;
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 5px;
  margin-right: 16px;
  color: #333333;
  padding: 7px;
}

.bulkActionsBtn {
  svg {
    position: relative;
  }
}

.toolbarRightСol {
  display: flex;
  align-items: center;
}