.infoWidget {
  border-radius: 10px;
  box-shadow: 1px 1px 7px #a7a4a4;
  background: white;
  margin: 0 25px
}

.infoWidgetType1 {
  width: 68%;

  .widgetList {
    display: flex;
    flex-wrap: wrap;
  }

  .widgetListItemValue {
    color: rgba(0, 0, 0, 0.54);
  }

  .widgetListItem {
    width: 33.3333%;

    svg {
      color: #2B479D;
      width: 35px;
      height: 35px;
    }
  }
}

.groupTitle {
  padding: 0 27px 0;
}

.widgetListItemTitle {
  color: #A1A1A1;
}


.infoWidgetType1, .infoWidgetType2 {
  .widgetListItem {
    margin: 20px 0 0;
    padding: 0 10px 0 50px;
    min-height: 39px;
  }
}



.infoWidgetType2 {
  width: 32%;

  .widgetListItem {
    color: #A1A1A1;

    svg {
      color: #A1A1A1;
      width: 29px;
      height: 29px;
    }
  }

  .widgetListItemValue {
    color: #A1A1A1
  }

  a {
    color: #3F61B7;
    transition: .5s;

    &:hover {
      opacity: .7;
    }
  }
}

.widgetListItem {
  position: relative;
  box-sizing: border-box;

  svg {
    position: absolute;
    left: 0;
    top: 4px;
  }
}


.container {
  max-width: 1470px;
  padding: 0 0 0 57px;
}

.containerSingleWidget {
  max-width: 955px;

  .infoWidget {
    width: 100%;
  }
}

.introBarContainer {
  display: flex;
  justify-content: space-between;
}

.widgetList {
  padding: 0 27px 20px;
}

.notesList {
  padding: 26px 34px 36px;
  color: rgba(0, 0, 0, 0.54);
}

.notesListItemTitle {
  color: #333333;
  font-size: 18px;
}

.widgetListItemValue {
  margin: 5px 0 0;
}

.notesListItemContent {
  padding: 8px 0 0;
}

.notesListItem +.notesListItem {
  padding: 20px 0 0;
}