@import 'config/theme.scss';

.root {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;

  .container {
    max-width: 620px;
    margin: auto;
    width: 100%;
  }

  .formWrapper {
    background: white;
    display: flex;
  }
}

.formTitle {
  font-size: 37px;
  text-align: center;
}

.authFormWrapper {
  margin: 0 0 0 auto;
  width: 100%;
  max-width: 390px;
  padding: 20px 0 0;
}

.companyLogo {
  width: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 100%;
  height: 100%;
}

.poweredBy {
  position: absolute;
  right: 20px;
  bottom: 20px;
}



