.root {
  .inputs-group5 {
    display: flex;
    align-items: flex-start;

    > div div + div {
      flex-direction: column !important;
    }
  }

  .inputs-group2 {
    .inputWrap {
      width: 100%;
    }
  }

  .inputs-group7 {
    display: flex;
  }
}

.form {
  margin: auto;
  max-width: 1130px;
}

.inputWrap {
  width: 25%;
  box-sizing: border-box;
  margin: 20px 0 0;
  padding: 0 10px 0;
  display: inline-flex;

  >div {
    width: 100%;
  }
}