.root {
  padding: 17px 40px 17px 29px;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  margin: 0 -10px;
  align-items: flex-start;

  >div {
    margin: 0 10px;
  }
}