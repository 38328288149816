.singlePage__container {
  padding: 0 map-deep-get($theme, 'mainLayout', 'paddingRight') 0 map-deep-get($theme, 'mainLayout', 'paddingLeft');
}

.singlePage__title4 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  margin: 20px 0;
}

.singlePage__sectoin {
  padding: 20px 0 0;
}