.root {
  .groupInputsList {
    >div {
      margin: 0;
    }
    input {
      width: 120px;
    }
  }
  .reportDesc {
    font-size: 15px;
  }

  td {
    padding: 10px;
  }
  .actionIcon {
    background: #fff;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25);
    color: #3F51B5;
    width: 36px;
    height: 36px;
    margin-left: 16px;
    padding: 5px;
  }
}

.groupTitle {
  font-weight: 700;
}
.formGroup {
  margin: 0 20px;
  display: inline-block;
  width: 100%;
}