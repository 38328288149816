@import "config/theme.scss";

.root {
  //padding: 0 map-deep-get($theme, 'mainLayout', 'paddingRight') 0 map-deep-get($theme, 'mainLayout', 'paddingLeft');
  margin: 0 -10px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  > div {
    padding: 10px 10px 0;
    width: 100%;
  }
}