@import "config/theme.scss";

.root {
  padding: 0 0 20px;
}

.introBar {
  background: map-deep-get($theme, 'palette', 'secondary2', 'main');
  padding: 16px 0 62px;
}
.introBarTitle {
  color: white;
  font-size: 26px;
  line-height: 38px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1000px) {
    font-size: 20px;
  }
}

.copyBarTitle {
  color: white;
  font-size: 20px;
  line-height: 1.2;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1000px) {
    font-size: 16px;
  }
}

.tabItem {
  margin: 12px 14.3px 0;
  font-weight: 700;
  letter-spacing: -0.1;
  cursor: pointer;
  transition: .5s;
  opacity: 0.5;
}

.tabItem__active {
  opacity: 1;
}

.tabControls {
  display: flex;
  flex-wrap: wrap;
  color: white;
  margin: 0 -14.3px;
  list-style: none;
  padding: 10px 0 0;
}

.mainHeaderButtonGroup {
  margin: 0 -10px;
  display: flex;
}

.mainHeaderButtonWrap {
  margin: 0 10px;
}

.currentStatus {
  text-decoration: none;
  color: white;
  cursor: pointer;
  transition: .5s;

  &:hover {
    opacity: .7;
  }
}