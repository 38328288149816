.autoSaveInputWrap {
    position: relative;
}

.autoSaveInputProgress {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(#ffffff, .7);
    z-index: 10;
}