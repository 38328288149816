@import 'config/theme.scss';

.discrepancyMsgWrap {
  padding: 20px 41px;
}

.discrepancyMsg {
  padding: 8px;
  background: rgba(map-deep-get($theme, 'palette', 'error', 'main'), .2);
  display: inline-block;
  border-radius: 5px;
}
