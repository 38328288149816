.root {
  max-width: 600px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
  width: 100%;
  border-radius: 10px;
  padding: 37px 45px;
  box-sizing: border-box;
  overflow: visible !important;

  .buttonGroup {
    text-align: center;
    padding: 40px 0 0;
  }
}

.inputList {
  padding: 20px 0 0;
}