@import 'config/theme.scss';

.title {
  font-size: 22px;
  line-height: 32px;
}

.header {
  display: flex;
  padding: 18px 13px 12px 34px;
  justify-content: space-between;
  border-bottom: 1px solid #C9C9C9;
}

.root {
  border-radius: 10px;
  box-shadow: 1px 1px 7px #a7a4a4;
  background: white;
  max-width: 720px;
  position: relative;

  .inputItemLabel {
    color: #A1A1A1;
  }

  .inputsList {
    display: flex;
    flex-wrap: wrap;
    padding: 0 41px 42px;
    margin: -46px 0 0;
  }
}

.inputList {
  padding: 20px 41px 42px;
  margin: 0 -10px;
}

.inputWrap {
  margin: 16px 10px 8px;
  width: 100%;
  max-width: 260px;

  >div {
    width: 100%;
  }
}

.groupTitle {
  font-weight: 700;
  margin: 20px 10px 0;
}

.inputs-group2 {
  .groupInputsList {
    display: flex;
    .inputWrap {
      width: 33.333%;
    }
  }
}

.controls {
  margin: 0 -8px;

  .button {
    margin: 0 8px;
    font-size: 13px;
    padding: 8px 25px;
    border-radius: 5px;
    border: 1px solid;
  }

  .buttonContained{
    background: map-deep-get($theme, 'palette', 'primary', 'main');
    border-color: map-deep-get($theme, 'palette', 'primary', 'main');
    color: white;
  }
}

.inputWrap {
  width: 100%;
  max-width: none;
}